.section {
    margin: 1rem 0;
}

.sectionTitle {
    height: 22px;
    font-weight: 700;
    font-size: 16px;
    line-height: 21.86px;
    color: #2A31A4;
}

.inputRow {
    display: flex;
    gap: 6rem;
    align-items: center;
    margin-bottom: 2rem;
}

.input {
    width: 288px;
    height: 55px;
    border-radius: 14px;
    background-color: #F9F9F9;
}

.textarea {
    border-radius: 14px;
    background-color: #F9F9F9;
}

.label {
    font-weight: 600;
    font-size: 14px;
    line-height: 19.12px;
    color: #000;
}

.divider {
    background: #A8A8A8;
    height: 0.5px;
    margin-top: 2rem;
    margin-right: -2rem;
}