.inputContainer {
    display: flex;
    width: 65rem;
    gap: 1rem;
    flex-wrap: wrap;
}

.inputContainer-grid {
    display: grid;
    grid-template-columns: repeat(3,368px);
    width: 65rem;
    gap: 1rem;
}

.inputRow {
    display: flex;
    align-items: center;
}

.controls {
    display: flex;
    align-items: center;
    gap: 6rem;
    margin-top: 1rem;
}
.textbox {
    border:1px solid #ced4da;
    border-radius:4px;
    margin:0 1rem;
    outline:none;
    padding:8px;
    box-sizing:border-box;
    transition:.3s;
    color: #495057;
    font-size: 1rem;
}
.textbox:focus{
    border-color:dodgerBlue;
    box-shadow:0 0 4px 0 dodgerBlue;
}

.textarea {
    resize: none;
}

.select {
    background: #fff;
}

.btn {
    cursor: pointer;
}

.addBtn {
    position: absolute;
    top: 0;
    right: 0;
    background-image: url(../../assets/add_btn.png);
    background-repeat: no-repeat;
    background-color: #fff;
    border: none;
    width: 3rem;
    height: 3rem;
}

.removeBtn {
    background-image: url(../../assets/x.png);
    background-repeat: no-repeat;
    background-color: #fff;
    border: none;
    width: 2rem;
    height: 2rem;
    position: relative;
    top: 24px;
    left: 3px;
}

.rowSeperator {
    background: #ced4da;
    height: 1px;
    margin-top: 1rem;
}

.error {
    border: 1px solid rgb(224 49 49);
}

.errorMessage {
    position: absolute;
    color: rgb(250 82 82);
    font-size: 12px;
    margin: 0;
    padding-left: 1rem;
    margin-top: 5px;
}

